@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "noto";
  src: url("../fonts/noto_sans/NotoSans-Regular.ttf");
}

html {
  height: -webkit-fill-available;
}
/* 
.min-h-screen {
  min-height: 100vh;
  min-height: -webkit-fill-available;
} */
